import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/perspective.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { FaPhone } from "react-icons/fa6";


export const Footer = () => {
  return (
    <footer className=" text-gray-300 py-6 shadow-md" style={{ backgroundColor:'#0d0d0d',boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.2)'}}>
      <Container>
        <Row className="flex flex-col md:flex-row items-center justify-between">
          <Col sm={12} md={4} className="text-center mb-4 md:mb-0">
            <img src={logo} alt="Perspective" className="w-24 mx-auto mb-2" />
            <p className="text-sm">EMPOWERING YOUR BUSINESS</p>
          </Col>
          <Col sm={12} md={4} className="text-center mb-4 md:mb-0">
      
            <p className="text-sm">Call Us- +251 944 216 265</p>
          </Col>
          <Col sm={12} md={4} className="text-center">
            <div className="social-icon mb-2">
              <a href="https://www.linkedin.com/in/perspective-8b7090238" className="inline-block w-8 h-8 mx-2">
                <img src={navIcon1} alt="Icon" className="transition duration-300 hover:scale-110" />
              </a>
              <a href="https://www.instagram.com/perspective_technologies/" className="inline-block w-8 h-8 mx-2">
                <img src={navIcon3} alt="Icon" className="transition duration-300 hover:scale-110" />
              </a>
            </div>
            <p className="text-sm">© 2023 Perspective Technologies. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

