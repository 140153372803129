const navbarItems = [
  {
    title: "Home",
    link: "/",
  },

  {
    title: "About",
    link: "/About",
  },
  {
    title: "Jobs",
    link: "/JobOpportunities",
  },


];

export default navbarItems;
