import React from "react";
import "./Navbar.css";
import navbarItems from "../NavbarItems";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import logo from '../../assets/img/perspective.png';

const Navbar = ({ toggle }) => {
  return (
    <nav 
    style={{ 
      borderRadius: '2px', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between' 
    }}
  >
    <Link to="/" className="link">
      <img 
        src={logo} 
        alt="PERSPECTIVE" 
        style={{ width: '150px', height: 'auto' }} 
      />
    </Link>
    <div className="menu-items">
      {navbarItems.map((item, index) => (
        <Link className="link" to={item.link} key={index}>
          {item.title}
        </Link>
      ))}
    </div>
    <div className="icons">
      <Link to="/ProductShop" className="link">
        <div className="navbar-shop">
          <div className="shicon">
            <FaCartPlus className="beat-animation" />
          </div>
        </div>
      </Link>
      <div className="mobile-menu-icon">
        <FaBars onClick={toggle} />
      </div>
    </div>
  </nav>
  
  );
};

export default Navbar;
